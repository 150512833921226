/*eslint-disable*/
import React from "react";
import {Alert, Button, notification} from 'antd';
import { Link } from "gatsby";
import { FaBars } from "react-icons/fa";
import UserDropdown from "../Dropdowns/UserDropdown";
import SecureLS from "secure-ls";
import PagesDropdown from "../Dropdowns/PagesDropdown";
import Api from "../common/Api";
import '../../styles/index.css';
import '../../styles/tailwind.css';

export default function Navbar(props) {
  let userId = 0;
  let userRole = '';
  let userIsActivated = '0';
  if (typeof localStorage !== 'undefined') {
    const ls = new SecureLS({encodingType: 'aes'});
    userId = ls.get('secret_key').userId;
    userRole = ls.get('secret_key').userRole;
    userIsActivated = ls.get('secret_key').userIsActivated;
  }
  
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a href="https://dil.denizcirpan.com/"
               className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap"
            >
              Deniz Çırpan
            </a>
            <button
              className="cursor-pointer text-xl text-white leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FaBars />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <Link
                  className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                  to="https://dil.denizcirpan.com/"    
                >
                  <i className="lg:text-gray-300 text-gray-500 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                  Egzersiz Kütüphanesi
                </Link>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menü 1
                </a>
              </li>
              <li className="flex items-center">
                <PagesDropdown />
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 2
                </a>
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 3
                </a>
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 4
                </a>
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 5
                </a>
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 6
                </a>
              </li>
              <li className="flex items-center">
                <a
                    className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs font-bold"
                    href="https://dil.denizcirpan.com/"
                    target="_blank"
                >
                  Menu 7
                </a>
              </li>
              {userId && userRole !== 'guest_customer' ? 
                  <li className="flex items-center">
                    <UserDropdown/>
                  </li> : 
                  <li className="flex items-center">
                    <Link to="/auth/login">
                      <button
                          className="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                          type="button"
                      >
                        <i className="fas fa-arrow-alt-circle-right">
                          
                        </i> Giriş Yap
                      </button>
                    </Link>
                  </li>}
            </ul>
          </div>
        </div>
        {userIsActivated === '0' ? 
            <Alert style={{width: '100%', textAlign: 'center'}} message={
              <>
                <p>Lütfen hesabınızı e-mailinize gelen mail ile onaylayınız, yoksa egzersiz yapamazsınız!</p>
                <p>E-Mail SPAM veya JUNK Klasörünüze Düşmüş Olabilir</p>
                <Button 
                    type="danger"
                    onClick={() => {
                      const openSendActCodeAgainNotificationWithIcon = type => {
                        notification[type]({
                          message: 'Başarıyla yeniden aktivasyon kodu gönderildi!',
                          description:
                              'Lütfen emailinizin SPAM veya JUNK klasörünü de kontrol ediniz. Eğer aktivasyon maili hala gelmediyse bizimle iletişime geçiniz!',
                        });
                      };
                      Api.sendActivationCodeAgain({
                        userId: userId
                      })
                        .then((response) => {
                          if(response.data.code === 1){
                            openSendActCodeAgainNotificationWithIcon('success')
                          }
                        })
                        .catch((err) => console.log(err.response.request));
                    }}
                >Aktivasyon Mailini Tekrar Gönder!</Button>
              </>
            } type="error" /> :
            ''
        }
      </nav>
    </>
  );
}
